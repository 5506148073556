import React, { useEffect, useState } from 'react'
import map from "../../images/map.png";
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

const Footer = () => {
    const [resources, setResources] = useState({});

    let navigate = useNavigate();

    const { language } = useParams();

    useEffect(() => {
        requestResources();
    }, [language]); //everytime language is changed

    const requestResources = async () => {
        await axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data?.footer);
            })
            .catch((error) => console.error(error.response.data));
    };
    return (
        <div className="container w-75 pb-5 footer">
            <div className="row text-start">
                <div className="col-md-3 pe-4 mb-3">
                    <div className="p-4 footer-box">
                        <p><b>{resources?.Information}</b></p>

                        <p className="m-0"><a className="footer-url" href="https://www.swissclassicworld.ch/news">{resources?.Press}</a></p>
                        <p className="m-0"><a className="footer-url" href="https://www.swissclassicworld.ch/kopie-von-datenschutz">{resources?.Imprint}</a></p>
                        <p className="m-0"><a className="footer-url" href="https://www.swissclassicworld.ch/datenschutz">{resources?.PrivacyPolicy}</a></p>
                        <p><a className="footer-url" href="https://www.swissclassicworld.ch/kontakt">{resources?.Contact}</a></p>

                        <p><b>{resources?.Contact}</b></p>

                        <p className="m-0">{resources?.SwissClassicWorld}</p>
                        <p className="m-0">{resources?.MarketingLinkGmbH}H</p>
                        <p className="m-0">{resources?.AddressLine1}</p>
                        <p className="m-0">{resources?.AddressLine2}</p>
                    </div>
                </div>
                <div className="col-md-3 pe-4 mb-3">
                    <div className="p-4 footer-box">
                        <p><b>{resources?.OpeningHours}</b></p>

                        <p className="m-0">{resources?.Friday}</p>
                        <p>{resources?.FridayHours}</p>

                        <p className="m-0">{resources?.Saturday}</p>
                        <p>{resources?.SaturdayHours}</p>

                        <p className="m-0">{resources?.Sunday}</p>
                        <p>{resources?.SundayHours}</p>

                        <p className="m-0">{resources?.ChangesReserved}</p>
                    </div>
                </div>
                <div className="col-md-3 pe-4 mb-3">
                    <div className="p-4 footer-box">
                        <p><b>{resources?.Newsletter}</b></p>

                        <p className="m-0">{resources?.SignupAndFeedback}</p>
                        <p className="m-0">{resources?.SignupAndFeedback1}</p>
                        <p>{resources?.SignupAndFeedback2}</p>

                        <p className="m-0">{resources?.FeedbackMail}</p>
                        <p className="m-0">{resources?.FeedbackMail1}</p>
                        <p className="m-0">{resources?.FeedbackMail2}</p>
                        <p className="m-0"><a href="mailto:info@swissclassicworld.ch">info@swissclassicworld.ch</a></p>
                    </div>
                </div>
                <div className="col-md-3 pe-4 mb-3">
                    <div className="p-4 footer-box">
                        <p><b>LOCATION</b></p>

                        <p>Luzern/Allmend</p>

                        <p><a href="https://www.swissclassicworld.ch/location"><img src={map} className="img-fluid" alt="swissmap" /></a></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer