import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "ticketino-api-client";
import Header from "./components/Header";
import Footer from "./components/Footer";

const Confirmation = () => {
    const { orderId } = useParams();

    const [token] = useState(sessionStorage.getItem("token"));
    const [order, setOrder] = useState([]);
    const [organizerId, setOrganizerId] = useState(0);

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    // setting the base url of the npm package api calls
    api.defaults.baseURL = baseUrl;

    // fetching resources
    const [resources, setResources] = useState({});

    // fetching resources
    const { language } = useParams();

    let languageId = 0;

    // changing languageId according to the url
    switch (language) {
        case ("de" || "DE"):
            languageId = 1;
            break;
        case ("fr" || "FR"):
            languageId = 2;
            break;
        case ("en" || "EN"):
            languageId = 3;
            break;
        case ("it" || "IT"):
            languageId = 4;
            break;
        default:
            languageId = 0;
            break;
    }

    useEffect(() => {
        // gtm - purchase
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'purchase'
        });
    }, [])

    useEffect(() => {
        loadToken();
    }, []); //only on first page load

    useEffect(() => {
        requestResources();
    }, [language]); //everytime language is changed

    const loadToken = async () => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        api.defaults.headers.common['Authorization'] = "Bearer " + token;

        requestFormSettings()
    };

    const requestFormSettings = async () => {
        await axios.get(`form/formsettings`).then((res) => {
            setOrganizerId(res.data.organizerId);
            loadOrder(orderId);
        });
    };

    const requestResources = async () => {
        await axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data.translation);
            })
            .catch((error) => console.error(error.response.data));
    };

    const loadOrder = async (orderId) => {
        try {
            // Order
            const order = await getOrderByOrderId(orderId);

            setOrder(order);
        }
        catch (error) {
            console.error(error);
        }
    }

    const getOrderByOrderId = async (orderId) => {
        try {
            const res = await axios.get(`${baseUrl}/Order/${orderId}`);
            return res.data;
        }
        catch (error) {
            console.error(error);
        }
    }

    const getReceiptOfOrder = async () => {
        await axios
            .get(`${baseUrl}/Order/${orderId}/Receipt?organizerId=${organizerId}`, {
                responseType: "blob",
            })
            .then((res) => {
                const data = window.URL.createObjectURL(res.data);
                var link = document.createElement("a");
                link.href = data;
                link.download = "Receipt.pdf";
                link.click();
            })
            .catch((error) => console.error(error.response.data));
    };

    const getPdfTicketsOfOrder = async () => {
        await axios
            .get(
                `${baseUrl}/Order/${orderId}/TicketsPdf?organizerId=${organizerId}`,
                {
                    responseType: "blob",
                }
            )
            .then((res) => {
                const data = window.URL.createObjectURL(res.data);
                var link = document.createElement("a");
                link.href = data;
                link.download = "Tickets.pdf";
                link.click();
            })
            .catch((error) => console.error(error.response.data));
    };

    return (
        <div>
            <Header />
            <div className="container mt-4 page-container">
                <div className="mb-3">
                    <p className="fs-3">{resources.Confirmation1}</p>
                </div>
                <div className="mb-3">
                    <div className="row mb-3">
                        <div className="mb-3 col-md-12 ">
                            <p className="mb-0 fs-6">
                                {resources.Confirmation2}<span className="ms-2 fw-bold fs-6">{order?.invoiceNumber}</span>
                            </p>
                            <p className="mb-3 fs-6">
                                {resources.Confirmation3}<span className="ms-2 fw-bold fs-6">{order?.currency} {order?.totalPrice?.toFixed(2)}</span>
                            </p>
                            <p className="mb-0 fs-6">
                                {resources.Confirmation4}
                            </p>
                            <p className="mb-3 fs-6">
                                {resources.Confirmation5}
                            </p>

                        </div>

                        <div className="col-md-6 mb-4">
                            <button
                                className="button col-12"
                                onClick={() => getPdfTicketsOfOrder()}
                            >
                                {resources.DownloadTicket}
                            </button>
                        </div>

                        <div className="col-md-6 mb-4">
                            <button
                                className="button col-12"
                                onClick={() => getReceiptOfOrder()}
                            >
                                {resources.DownloadReceipt}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Confirmation