import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api, applyPromotionCode, getAddressOfOrder, getCountries, getEventById, getTicketTypeById, getTicketTypeInfosByTicketTypeId } from "ticketino-api-client";

import Header from './components/Header'
import Footer from "./components/Footer";

const Address = () => {
    const [orderId] = useState(sessionStorage.getItem("orderId"));
    const [token] = useState(sessionStorage.getItem("token"));

    const [buyerInfo, setBuyerInfo] = useState({
        salutation: "",
        firstName: "",
        name: "",
        email: "",
        street: "",
        houseNumber: "",
        mobile: "",
        city: "",
        postCode: "",
        countryId: 176,
        email: "",
        confirmemail: "",
    });

    // react hook for navigation
    let navigate = useNavigate();

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    // setting the base url of the npm package api calls
    api.defaults.baseURL = baseUrl;

    // fetching resources
    const [resources, setResources] = useState({});
    const [countries, setCountries] = useState([]);

    // fetching params
    const { language } = useParams();

    let languageId = 0;

    // changing languageId according to the url
    switch (language) {
        case ("de" || "DE"):
            languageId = 1;
            break;
        case ("fr" || "FR"):
            languageId = 2;
            break;
        case ("en" || "EN"):
            languageId = 3;
            break;
        case ("it" || "IT"):
            languageId = 4;
            break;
        default:
            languageId = 0;
            break;
    }

    useEffect(() => {
        requestResources();
        loadCountries();
    }, [language]); //everytime language is changed

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        api.defaults.headers.common['Authorization'] = "Bearer " + token;

        if (orderId) {
            loadOrder(orderId);
        }
    }, [orderId]); //gets called when an order is started

    const requestResources = async () => {
        await axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data?.translation);
            })
            .catch((error) => console.error(error.response.data));
    };

    const removePromotionCode = async (promotion) => {
        try {
            const response = axios.delete(`${baseUrl}/ShopBasket/Order/${orderId}/PromotionCode/${promotion}`);
            return response.data;
        } catch (error) {
            console.error(error);
        }
    }

    const loadCountries = async () => {
        try {
            let countries = await getCountries(language);

            const indexCH = countries.findIndex(c => c.id === 176);
            const ch = countries[indexCH];
            countries.splice(indexCH, 1);

            var indexDE = countries.findIndex(c => c.id === 229);
            const de = countries[indexDE];
            countries.splice(indexDE, 1);

            var indexAU = countries.findIndex(c => c.id === 228);
            const au = countries[indexAU];
            countries.splice(indexAU, 1);

            countries.unshift({ id: 0, name: "---------" });
            countries.unshift(au);
            countries.unshift(de);
            countries.unshift(ch);

            setCountries(countries);
        }
        catch (error) {
            console.error(error);
        }
    }

    const loadOrder = async (orderId) => {
        try {
            const address = await getAddressOfOrder(orderId);
            setBuyerInfo({ ...buyerInfo, ...address });
        }
        catch (error) {
            console.error(error);
        }
    }

    const onInputChange = (e) => {
        setBuyerInfo({ ...buyerInfo, [e.target.name]: e.target.value });
    };

    const addAddressToBasket = async () => {
        let addressBody = { ...buyerInfo, street: buyerInfo.street + " " + buyerInfo.houseNumber };

        await axios
            .put(`${baseUrl}/Order/${orderId}/Address`, addressBody)
            .then(() => {
                // gtm - addAddress
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'addAddress'
                });

                navigate(`/${language}/checkout`)
            })
            .catch((error) => {
                console.error(error.response.data);
            });
    };

    let validation = /^.{1,70}$/.test(buyerInfo.salutation) &&
        /^.{1,70}$/.test(buyerInfo.name) &&
        /^.{1,70}$/.test(buyerInfo.firstName) &&
        /^.{1,70}$/.test(buyerInfo.street) &&
        /^.{1,70}$/.test(buyerInfo.houseNumber) &&
        /^.{1,70}$/.test(buyerInfo.postCode) &&
        /^.{1,70}$/.test(buyerInfo.city) &&
        /^\S+@\S+\.\S+$/.test(buyerInfo.email) &&
        (buyerInfo.countryId > 0) &&
        (buyerInfo.email == buyerInfo.confirmemail);

    const onBackClick = async () => {
        const promotioncode = sessionStorage.getItem("addedPromotion");

        if (promotioncode?.length > 0) {
            await removePromotionCode(promotioncode);
        }

        navigate(-1)
    }

    return (
        <div>
            <Header />
            <div className="container mt-4 page-container">
                <p className="fs-5 fw-bold mb-2">{resources?.BuyerInformation}</p>
                <hr></hr>
                <div className="mb-3 row">
                    <label className="col-md-3 col-12 mb-2">
                        {resources?.BuyerSalutation}
                    </label>
                    <div className="col-md-9 col-12">
                        <div>
                            <select
                                className="form-select"
                                aria-label=""
                                id="salutation"
                                name="salutation"
                                value={buyerInfo.salutation}
                                onChange={onInputChange}
                            >
                                <option value=""></option>
                                <option value={resources?.Mr}>{resources?.Mr}</option>
                                <option value={resources?.Ms}>{resources?.Ms}</option>
                                <option value={resources?.Diverse}>{resources?.Diverse}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="mb-3 row">
                    <label className="col-md-3 col-12 mb-2" htmlFor="firstName">
                        {resources?.BuyerFirstname}*
                    </label>
                    <div className="col-sm-9 col-md-9 col-12">
                        <input
                            type="text"
                            name="firstName"
                            className="form-control"
                            value={buyerInfo.firstName}
                            id="firstName"
                            onChange={onInputChange}
                        />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label className="col-md-3 col-12 mb-2" htmlFor="name">
                        {resources?.BuyerLastname}*
                    </label>
                    <div className="col-sm-9 col-md-9 col-12">
                        <input
                            type="text"
                            name="name"
                            className="form-control"
                            value={buyerInfo.name}
                            id="name"
                            onChange={onInputChange}
                        />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label className="col-md-3 col-12 mb-2" htmlFor="email">
                        {resources?.BuyerEmail}*
                    </label>
                    <div className="col-sm-9 col-md-9 col-12">
                        <input
                            type="text"
                            name="email"
                            className="form-control"
                            value={buyerInfo.email}
                            id="email"
                            onChange={onInputChange}
                        />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label className="col-md-3 col-12 mb-2" htmlFor="confirmemail">
                        {resources?.BuyerConfirmEmail}*
                    </label>
                    <div className="col-sm-9 col-md-9 col-12">
                        <input
                            type="text"
                            name="confirmemail"
                            className="form-control"
                            value={buyerInfo.confirmemail}
                            id="confirmemail"
                            onChange={onInputChange}
                        />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label className="col-md-3 col-12 mb-2" htmlFor="street">
                        {resources?.BuyerStreet}*
                    </label>
                    <div className="col-md-9 col-12 row pe-0">
                        <div className="col-md-8 col-12 pe-0">
                            <input
                                type="text"
                                name="street"
                                className="form-control"
                                value={buyerInfo.street}
                                id="street"
                                onChange={onInputChange}
                            />
                        </div>
                        <div className="col-md-4 col-12 pe-0">
                            <input
                                type="text"
                                name="houseNumber"
                                className="form-control"
                                value={buyerInfo.houseNumber}
                                id="houseNumber"
                                onChange={onInputChange}
                            />
                        </div>
                    </div>
                </div>
                <div className="mb-3 row">
                    <label className="col-md-3 col-12 mb-2" htmlFor="postCode">
                        {resources?.BuyerPostcode}*
                    </label>
                    <div className="col-md-9 col-12 row pe-0">
                        <div className="col-md-4 col-12 pe-0">
                            <input
                                type="text"
                                name="postCode"
                                className="form-control"
                                value={buyerInfo.postCode}
                                id="postCode"
                                onChange={onInputChange}
                            />
                        </div>
                        <div className="col-md-8 col-12 pe-0">
                            <input
                                type="text"
                                name="city"
                                className="form-control"
                                value={buyerInfo.city}
                                id="city"
                                onChange={onInputChange}
                            />
                        </div>
                    </div>
                </div>
                <div className="mb-3 row">
                    <label className="col-md-3 col-12 mb-2">
                        {resources?.BuyerCountry}*
                    </label>
                    <div className="col-md-9 col-12">
                        <div>
                            <select
                                className="form-select"
                                aria-label=""
                                id="countryId"
                                name="countryId"
                                value={buyerInfo.countryId}
                                onChange={onInputChange}
                            >
                                {
                                    countries.map((country, index) => (
                                        <option value={country.id}>{country.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                </div>
                <div className="mb-3 row">
                    <label className="col-md-3 col-12 mb-2" htmlFor="street">
                        {resources?.BuyerNumber}*
                    </label>
                    <div className="col-sm-9 col-md-9 col-12">
                        <input
                            type="text"
                            name="mobile"
                            className="form-control"
                            value={buyerInfo.mobile}
                            id="mobile"
                            onChange={onInputChange}
                        />
                    </div>
                </div>
                <div className="row mt-4 mb-4">
                    <div className=" col-6 text-start">
                        <button className="button" onClick={onBackClick}>
                            {resources?.Back}
                        </button>
                    </div>
                    <div className="col-6 text-end">
                        <button className="button" disabled={!validation} onClick={addAddressToBasket}>{resources.Buy}</button>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Address