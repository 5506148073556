import React, { useEffect, useState } from 'react'
import logo from "../../images/logo.png"
import banner from "../../images/banner.png";
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

const Header = () => {
    const [resources, setResources] = useState({});

    let navigate = useNavigate();

    const { language } = useParams();

    const [showMenus, setShowMenus] = useState({
        main: false,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false
    })

    useEffect(() => {
        requestResources();
    }, [language]); //everytime language is changed

    const requestResources = async () => {
        await axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data?.header);
            })
            .catch((error) => console.error(error.response.data));
    };

    const onSubMenuClick = (e) => {
        let name = e.currentTarget.getAttribute('name');
        setShowMenus({ ...showMenus, [name]: !showMenus[name] })
    }

    return (
        <>
            <div className="header">
                <div className="row text-center navbar">
                    <div className="col-2 p-0 image-fluid">
                        <img src={logo} className="img-fluid ps-5" alt="logo" />
                    </div>
                    <div className="nav-element-container col p-0">
                        <div className="nav-element">
                            <a href="https://www.swissclassicworld.ch/home">{resources?.Home}</a>
                        </div>
                        <div className="nav-element dropdown ps-0">
                            <a href="https://www.swissclassicworld.ch/messe">{resources?.Exhibition}</a>
                            <div className="dropdown-content">
                                <li><a href="https://www.swissclassicworld.ch/team">{resources?.OurTeam}</a></li>
                                <li><a href="https://www.swissclassicworld.ch/location">{resources?.Location}</a></li>
                            </div>
                        </div>
                        <div className="nav-element dropdown ps-0">
                            <a href="https://www.swissclassicworld.ch/aussteller">{resources?.Exhibitors}</a>
                            <div className="dropdown-content">
                                <li><a href="https://aussteller.swissclassicworld.ch/">{resources?.OnlineRegistration}</a></li>
                                <li><a href="https://www.swissclassicworld.ch/anmeldung-formular">{resources?.RegistrationForm}</a></li>
                                <li><a href="https://www.swissclassicworld.ch/download">{resources?.InformationAndDownload}</a></li>
                            </div>
                        </div>
                        <div className="nav-element dropdown ps-0">
                            <a href="https://www.swissclassicworld.ch/besucher">{resources?.Visitors}</a>
                            <div className="dropdown-content">
                                <li><a href="https://www.tickets.swissclassicworld.ch">{resources?.Tickets}</a></li>
                                <li><a href="https://www.swissclassicworld.ch/programm">{resources?.Program}</a></li>
                                <li><a href="https://www.swissclassicworld.ch/ausstellerliste">{resources?.HallPlanExhibitors}</a></li>
                                <li><a href="https://www.swissclassicworld.ch/messemagazin">{resources?.ExhibitionMagazine}</a></li>
                            </div>
                        </div>
                        <div class="nav-element ps-0">
                            <a href="https://rallye.swissclassicworld.ch/">{resources?.Rally}</a>
                        </div>
                        <div className="nav-element dropdown ps-0">
                            <a href="https://www.swissclassicworld.ch/medien">{resources?.Media}</a>
                            <div className="dropdown-content">
                                <li><a href="https://www.swissclassicworld.ch/news">{resources?.News}</a></li>
                                <li><a href="https://www.swissclassicworld.ch/media-downloads">{resources?.DownloadsAndImages}</a></li>
                                <li><a href="https://www.swissclassicworld.ch/akkreditierung">{resources?.Accreditation}</a></li>
                            </div>
                        </div>
                        <div class="nav-element ps-0">
                            <a href="https://www.swissclassicworld.ch/fahrzeugmarkt">{resources?.VehicleMarket}</a>
                        </div>
                        <div className="nav-element dropdown ps-0">
                            <a href="/">{resources?.MembersClub}</a>
                            <div className="dropdown-content">
                                <li><a href="https://www.swissclassicworld.ch/members-club">{resources?.Registration}</a></li>
                                <li><a href="https://www.swissclassicworld.ch/members-club-angebote">{resources?.Offers}</a></li>
                            </div>
                        </div>
                        <div className="nav-element dropdown ps-0">
                            <a href="/">{resources?.Events}</a>
                            <div className="dropdown-content">
                                <li><a href="https://www.swissclassicworld.ch/harder-than-steel">{resources?.HarderThanSteel}</a></li>
                                <li><a href="https://www.swissclassicworld.ch/forever-young">{resources?.ForeverYoung}</a></li>
                                <li><a href="https://www.swissclassicworld.ch/old-cars-for-young-people">{resources?.OldCarsForYoungPeople}</a></li>
                                <li><a href="https://www.swissclassicworld.ch/dance-night">{resources?.RockNRollDanceNight}</a></li>
                            </div>
                        </div>
                        <div className="nav-element ps-0">
                            <a href="https://www.swissclassicworld.ch/kontakt">{resources?.Contact}</a>
                        </div>
                    </div>
                    <div className='col-md-1 col-4'>
                        <select className='nav-select form-select' value={language} onChange={(e) => navigate(`/${e.target.value}/home`)}>
                            <option value={"de"}>DE</option>
                            <option value={"en"}>EN</option>
                        </select>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 fill">
                        <img src={banner} alt="" className="img-fluid" />
                    </div>
                </div>
            </div>

            <div class="mobile-header">
                <div class="row text-center logo-div">
                    <div class="col-12 fill">
                        <img src={logo} class="img-fluid" alt="logo" />
                    </div>
                </div>
                <div class="row p-3">
                    <i class="bi bi-list fs-2 fw-bold"
                        id="open-mobile-nav"
                        name="main"
                        onClick={onSubMenuClick}>
                    </i>
                </div>
                {(showMenus.main == true) && <div id="mobile-nav" class="row p-3">
                    <ul>
                        <li><a href="https://www.swissclassicworld.ch/home">{resources?.Home}</a></li>
                        <li>
                            <i class={`bi bi-chevron-${showMenus[1] ? "up" : "down"} me-4 toggle-submenu`} onClick={onSubMenuClick} name="1"></i>
                            <a href="https://www.swissclassicworld.ch/messe">{resources?.Exhibition}</a>
                            {(showMenus[1] == true) && <ul class="sub-menu" id="submenu1">
                                <li><a href="https://www.swissclassicworld.ch/team">{resources?.OurTeam}</a></li>
                                <li><a href="https://www.swissclassicworld.ch/location">{resources?.Location}</a></li>
                            </ul>}
                        </li>

                        <li>
                            <i class={`bi bi-chevron-${showMenus[2] ? "up" : "down"} me-4 toggle-submenu`} onClick={onSubMenuClick} name="2"></i>
                            <a href="https://www.swissclassicworld.ch/aussteller">{resources?.Exhibitors}</a>
                            {(showMenus[2] == true) && <ul class="sub-menu" id="submenu2">
                                <li><a href="https://aussteller.swissclassicworld.ch/">{resources?.OnlineRegistration}</a></li>
                                <li><a href="https://www.swissclassicworld.ch/anmeldung-formular">{resources?.RegistrationForm}</a></li>
                                <li><a href="https://www.swissclassicworld.ch/download">{resources?.InformationAndDownload}</a></li>
                            </ul>}
                        </li>
                        <li>
                            <i class={`bi bi-chevron-${showMenus[3] ? "up" : "down"} me-4 toggle-submenu`} onClick={onSubMenuClick} name="3"></i>
                            <a href="https://www.swissclassicworld.ch/besucher">{resources?.Visitors}</a>
                            {(showMenus[3] == true) && <ul class="sub-menu" id="submenu3">
                                <li><a href="https://www.tickets.swissclassicworld.ch">{resources?.Tickets}</a></li>
                                <li><a href="https://www.swissclassicworld.ch/programm">{resources?.Program}</a></li>
                                <li><a href="https://www.swissclassicworld.ch/ausstellerliste">{resources?.HallPlanExhibitors}</a></li>
                                <li><a href="https://www.swissclassicworld.ch/messemagazin">{resources?.ExhibitionMagazine}</a></li>
                            </ul>}
                        </li>
                        <li><a href="https://rallye.swissclassicworld.ch/">{resources?.Rally}</a></li>
                        <li>
                            <i class={`bi bi-chevron-${showMenus[4] ? "up" : "down"} me-4 toggle-submenu`} onClick={onSubMenuClick} name="4"></i>
                            <a href="https://www.swissclassicworld.ch/medien">{resources?.Media}</a>
                            {(showMenus[4] == true) && <ul class="sub-menu" id="submenu4">
                                <li><a href="https://www.swissclassicworld.ch/news">{resources?.News}</a></li>
                                <li><a href="https://www.swissclassicworld.ch/media-downloads">{resources?.DownloadsAndImages}</a></li>
                                <li><a href="https://www.swissclassicworld.ch/akkreditierung">{resources?.Accreditation}</a></li>
                            </ul>}
                        </li>
                        <li><a href="https://www.swissclassicworld.ch/fahrzeugmarkt">{resources?.VehicleMarket}</a></li>
                        <li>
                            <i class={`bi bi-chevron-${showMenus[5] ? "up" : "down"} me-4 toggle-submenu`} onClick={onSubMenuClick} name="5"></i>
                            <a href="/">{resources?.MembersClub}</a>
                            {(showMenus[5] == true) && <ul class="sub-menu" id="submenu5">
                                <li><a href="https://partner.swissclassicworld.ch/">{resources?.Registration}</a></li>
                                <li><a href="https://partner.swissclassicworld.ch/angebote">{resources?.Offers}</a></li>
                            </ul>}
                        </li>
                        <li>
                            <i class={`bi bi-chevron-${showMenus[6] ? "up" : "down"} me-4 toggle-submenu`} onClick={onSubMenuClick} name="6"></i>
                            <a href="/">{resources?.Events}</a>
                            {(showMenus[6] == true) && <ul class="sub-menu" id="submenu6">
                                <li><a href="https://www.swissclassicworld.ch/harder-than-steel">{resources?.HarderThanSteel}</a></li>
                                <li><a href="https://www.swissclassicworld.ch/forever-young">{resources?.ForeverYoung}</a></li>
                                <li><a href="https://www.swissclassicworld.ch/old-cars-for-young-people">{resources?.OldCarsForYoungPeople}</a></li>
                                <li><a href="https://www.swissclassicworld.ch/dance-night">{resources?.RockNRollDanceNight}</a></li>
                            </ul>}
                        </li>
                        <li><a href="https://www.swissclassicworld.ch/kontakt">{resources?.Contact}</a></li>
                    </ul>
                </div>}
                <div class="row">
                    <div class="col-12 fill">
                        <img src={banner} alt="" class="img-fluid" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header